<template>
    <div class="main">
        <div class="container">
            <div class="title">
                <router-link
                        to="/orders/new"
                        class="btn float-right new-order-link hidden-xs"
                        v-show="hiddenPlatforms"
                        v-verify-scope-view-parent="userView_parent_orders">
                     {{ activeLanguage.newOrderBtn }}
                </router-link>
                <router-link
                        to="/credit/import"
                        class="btn float-right mr-3"
                        v-show="hiddenPlatforms"
                        v-verify-scope-view-parent="userView_parent_orders">
                    {{ activeLanguage.importCreditBtn }}
                </router-link>
                <h1>
                    {{ activeLanguage.title }}
                </h1>
            </div>
            <div class="filter-bar-container">
                <div class="filter-bar">
                    <div>
                        <button type="button" class="btn btn-secondary" @click="toggleFilter">
                            <i class="filter-icon fa fa-filter fa-lg" aria-hidden="true"></i>
                            {{ activeLanguage.filterBtn }}
                            <i class="fa fa-caret-down caret" aria-hidden="true"></i>
                        </button>
                        <form class="form-inline float-right" @submit.prevent="getOrders" v-show="!show_filter">
                            <input
                                class="form-control mr-sm-2"
                                type="text"
                                :placeholder="activeLanguage.searchText"
                                aria-label="Search"
                                v-model="activeFilters.invoice"
                            >
                            <button class="btn nopadding" type="submit"><i class="fa fa-search" aria-hidden="true"></i></button>
                        </form>
                    </div>
                </div>
                <transition name="collapse">
                    <OrdersFilter
                        @clearNum="onFiltersClear"
                        @onFiltersApply="onFiltersApply"
                        @downloadCsv="exportSelection($event, 'filter')"
                        ref="orderFilter"
                        :activeLanguage="activeLanguage.filterTable"
                        :showKVK="showKVK"
                        :isAdmin="isAdmin"
                        :startFromDate="manageOrderRight ? startFromDate : null"
                        :manageOrderRight="manageOrderRight"
                        :disableFilterApplyOnClear="true"
                        :savedFilters="storedFilters"
                        :key="startFromDate"
                    />
                </transition>
            </div>
            <div class="table-bar">
                <div class="download_container" style="margin-bottom: 10px;">
                    <button
                        @click.prevent="exportSelection(null, 'id')"
                        v-show="showExportSelectionBtn"
                        class="btn"
                    >
                        {{ activeLanguage.exportSelectionBtn }}
                    </button>
                    <button
                        @click.prevent="startWorkflow"
                        v-show="showExportSelectionBtn"
                        class="btn"
                        style="margin-left: 15px;"
                    >
                        Start Workflow
                    </button>
                </div>
            </div>
            <div v-if="showOrderTable">
              <OrderTable
                  :manageOrderRight="manageOrderRight"
                  :showKVK="showKVK"
                  :check="true"
                  :activeLanguage="activeLanguage.ordersTable"
                  :orders="orders"
                  :sorting="sorting"
                  @onSortChange="onSortChange"
                  @orderIsSelected="onOrderSelect"
              />
              <b-pagination
                  align="center"
                  :prev-text="activeLanguage.pagPrev"
                  :next-text="activeLanguage.pagNext"
                  :limit="7"
                  @input="updatePage"
                  :total-rows="pagData.maxItems"
                  v-model="pagData.currentPage"
                  :per-page="pagData.perPage"
                  v-if="pagData.maxItems > 20"
                  :disabled="disablePagination"
              />
              
              <div v-else style="height: 35px;"></div>
              <PaginationCount
                  :pagData="pagData"
                  :selection="csvIds"
                  :activeLanguage="activeLanguage.pagTxt"
                  :per-page="pagData.perPage"
              />
            </div>
          <div v-else>
            <h2 class="text-center"> {{ activeLanguage.initialLoadingText }}</h2>
          </div>
            <div style="display: flex" v-if="showOrderTable">
                <div class="pagItems">
                    <div class="pagItem" @click="setPerPage(20)" :class="pagData.perPage === 20 ? 'activeItem' : ''">20</div>
                    <div class="pagItem" @click="setPerPage(50)" :class="pagData.perPage === 50 ? 'activeItem' : ''">50</div>
                    <div class="pagItem" @click="setPerPage(100)" :class="pagData.perPage === 100 ? 'activeItem' : ''">100</div>
                    <div class="pagItem" style="border-right: none" @click="setPerPage(500)" :class="pagData.perPage === 500 ? 'activeItem' : ''">500</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import OrderTable from '@/components/common/reusable/OrdersTable.vue'
    import Pagination from '@/components/common/reusable/Pagination.vue'
    import OrdersFilter from '@/components/ordersView/OrdersFilter.vue'
    import PaginationCount from '@/components/common/reusable/PaginationCount.vue'
    import {mapGetters} from  "vuex"
    import moment from 'moment'

	export default {
		name: "orders",
        data() {
			return {
                orders: [],
				activeFilters: {},
                sorting: {
                	sort: 'order_date',
	                sort_dir: 'desc',
                },
				pagData: {
					currentPage: 1,
					maxItems: 0,
                    perPage: 20
                },
                showExportSelectionBtn: false,
				csvIds: [],
				disablePagination: false,
				showKVK: false,
                startFromDate: moment().subtract(5, 'months').date(1).format(),
                clientData: {},
                disable_platforms: ['pay_nl', 'sisow', 'curopayments', 'buckaroo'],
                show_filter: true,
        showOrderTable: false
            }
        },
        components:{
	        OrdersFilter,
	        OrderTable,
	        Pagination,
	        PaginationCount
        },
		computed: {
			...mapGetters({
				activeLanguage: 'getOrdersLanguage',
				manageOrderRight: 'userManage_orders',
                userView_parent_orders: 'userView_parent_orders',
                isAdmin: 'isAdmin',
                storedFilters: 'getOrdersFilters'
            }),
            hiddenPlatforms () {
                return !this.disable_platforms.includes(this.clientData.platform);
            }
    },
		mounted() {
            this.showOrderTable = !this.manageOrderRight || !!location.search;
            this.activeFilters = this.storedFilters ? this.storedFilters : this.activeFilters
            this.pagData.currentPage = this.$store.state.orderPage.currentPageState

            if (!!this.activeFilters && Object.keys(this.activeFilters).length !== 0) {
              this.getOrders(this.pagData.currentPage)
              this.showOrderTable = true  
            } else if (!this.manageOrderRight || !!location.search) {
              this.getOrders(this.pagData.currentPage)
            }
                this.$refs.orderFilter.toggle()
		},
		methods: {
      updatePage(page) {
        this.pagData.currentPage = page;
        this.$store.dispatch('setCurrentPage', page);
        this.getOrders(page);
      },
			getOrders(page = 1) {
				this.disablePagination = true
				this.showExportSelectionBtn = false
				this.csvIds = []
        let magnifyGlassFilter = this.$router.history.current.query

        if (magnifyGlassFilter) {
          this.activeFilters = {
            ...this.activeFilters,
            ...magnifyGlassFilter
          }
        }

				let params = {
                    filter: this.activeFilters,
					page,
                    limit: this.pagData.perPage,
                    ...this.sorting
                }

				billinkAxios
                    .get(`app/order-list`, { params })
					.then(({ data }) => {
						this.orders = data.order_list
						this.pagData.currentPage = data.page
                        this.pagData.maxItems = parseFloat(data.total_orders)
                        if (!this.isAdmin) {
                            this.getClientInfo(data.order_list[0].invoice)
                        }
                        this.showKVK = this.orders.some(item => parseFloat(item.coc_number) > 0) && !!this.activeFilters.cocNumber
                    })
					.catch(error => console.error(error))
                    .then(() => {
	                    this.disablePagination = false
                    })
            },
            getClientInfo (inv) {
                billinkAxios
                    .get(`app/order-details/${inv}/client`)
                    .then(({data}) => {
                        this.clientData = {
                            ...data
                        }
                    })
                    .catch(err => console.error(err))
            },
			onSortChange(type) {
                if (this.sorting.sort === type) {
					this.sorting.sort_dir = this.sorting.sort_dir === 'asc' ? 'desc' : 'asc'
                } else {
					this.sorting.sort_dir = 'desc'
                }
				this.sorting.sort = type

				this.getOrders()
            },
			onOrderSelect(ids) {
				this.showExportSelectionBtn = ids.length
                this.csvIds = ids
            },
			startWorkflow() {
				let params = {
					order_ids: this.csvIds
				}
				billinkAxios
					.post('/app/workflow/start', params)
					.then(({data}) => console.log(data))
					.catch(err => console.error(err))
            },
			exportSelection(filters, type) {
				let params = {}

				if ((type === 'id') && !!this.csvIds.length) {
					params.orders = this.csvIds
                } else if (type === 'filter') {
					params.filter = filters
                }

				billinkAxios
                    .get('app/order/export-csv', { params })
                    .then(({data}) => this.getCsvFile(data.url))
                    .catch(err => console.error(err))
            },
            getCsvFile(url) {
				billinkAxios
                    .get(url, { responseType: 'blob' })
                    .then((res) => {
	                    let link = document.createElement('a')

                        link.href = window.URL.createObjectURL(res.data)
	                    link.setAttribute('download', 'Selectie.csv');
	                    document.body.appendChild(link);
	                    link.click();
	                    link.remove();
                    })
                    .catch(err => console.error(err))
            },
			onFiltersApply(data) {
                this.$store.dispatch('setOrdersFilters', data)
			    this.showOrderTable = true;
				this.activeFilters = data
				this.getOrders()
            },
            onFiltersClear() {
                delete this.activeFilters.invoice
                this.$store.dispatch('destroyOrdersFilters')
            },
			toggleHelpSidebar() {
				this.$store.dispatch('toggleHelpModal')
			},
			toggleFilter() {
                this.$refs.orderFilter.toggle()
                this.show_filter = !this.show_filter
            },
            setPerPage(val) {
                this.pagData.perPage = val
                this.getOrders()
            }
		}
	}
</script>

<style lang="scss">
    .loading-parent {
        position: relative;
    }
    .pagination {
        z-index: 2;
    }
    .pagItems {
        display: inline-flex;
        border: 1px solid #032438;
        border-radius: 0.25rem;
        opacity: 0.9;
        margin-left: auto;
        margin-top: -72px;
        height: 36px;
        cursor: pointer;
    }
    .pagItem {
        padding: 8px 12px;
        border-right: 1px solid #032438;
        color: #007BFF;
    }
    .pagItem:hover {
        background-color: #e9ecef;
    }
    .activeItem {
        background-color: #f48d21 !important;
        border-color: #f48d21 !important;
        color: #ffffff !important;
    }
</style>