<template>
    <div class="collapse show" id="filtercollapse" v-if="show">
        <div class="filter-content">
            <form @submit.prevent="applyFilters">
                <div class="form-row">
                    <div class="form-group col-md-8">
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label for="input1" class="col-form-label">{{ activeLanguage.name }}</label>
                                <input v-model="form.name" type="text" class="form-control" id="input1">
                            </div>
                            <div class="form-group col-md-4">
                                <label for="input2" class="col-form-label">{{ activeLanguage.surname }}</label>
                                <input v-model="form.surname" type="text" class="form-control" id="input2">
                            </div>
                            <div class="form-group col-md-4">
                                <label for="input3" class="col-form-label">{{ activeLanguage.companyName }}</label>
                                <input v-model="form.company" type="text" class="form-control" id="input3">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label for="input4" class="col-form-label">{{ activeLanguage.zip }}</label>
                                <input v-model="form.zip" type="text" class="form-control" id="input4">
                            </div>
                            <div class="form-group col-md-4">
                                <label for="input5" class="col-form-label">{{ activeLanguage.houseNumber }}</label>
                                <input v-model="form.house" type="text" class="form-control" id="input5">
                            </div>
                            <div class="form-group col-md-4">
                                <label for="input6" class="col-form-label">{{ activeLanguage.email }}</label>
                                <input v-model="form.email" type="text" class="form-control" id="input6">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-4" v-if="manageOrdersPermission || isSuperUser">
                                <label for="input7" class="col-form-label">{{ activeLanguage.client }}</label>
                                <v-autocomplete
                                    :min-len="2"
                                    v-model="client"
                                    :items="clients"
                                    :get-label="getLabel"
                                    :component-item='itemTemplate'
                                    @update-items="updateClientList"
                                    @item-selected="clientSelected"
                                >
                                </v-autocomplete>
                            </div>
                            <div class="form-group col-md-4">
                                <label for="input8" class="col-form-label">{{ activeLanguage.kvk }}</label>
                                <input v-model="form.cocNumber" type="text" class="form-control" id="input8">
                            </div>
                            <div class="form-group col-md-4">
                                    <label for="input9" class="col-form-label">{{ activeLanguage.searchText }}</label>
                                    <input
                                        class="form-control mr-sm-2"
                                        type="text"
                                        aria-label="Search"
                                        v-model="form.invoice"
                                    >
                            </div>
                            <div class="form-group col-md-4" v-if="!!manageOrderRight">
                                    <label for="input10" class="col-form-label">Merchant</label>
                                    <input
                                        class="form-control mr-sm-2"
                                        type="text"
                                        aria-label="Merchant"
                                        v-model="form.merchant_name"
                                    >
                            </div>
                            <div class="form-group col-md-4">
                                    <label for="input11" class="col-form-label">{{ activeLanguage.phoneNumber }}</label>
                                    <input
                                        class="form-control mr-sm-2"
                                        type="text"
                                        v-model="form.customerPhone"
                                    >
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-md-4">
                        <label for="input4" class="col-form-label pl-2">{{ activeLanguage.status }}</label>
                        <div class="form-row">
                            <div class="form-group col-md-6 pl-3">
                                <label class="custom-control custom-checkbox">
                                    <input v-model="prestatus.created" type="checkbox" class="custom-control-input">
                                    <span class="custom-control-indicator"></span>
                                    <span class="custom-control-description">{{ activeLanguage.notStarted }}</span>
                                </label>

                                <label class="custom-control custom-checkbox">
                                    <input v-model="prestatus.open" type="checkbox" class="custom-control-input">
                                    <span class="custom-control-indicator"></span>
                                    <span class="custom-control-description">{{ activeLanguage.pending }}</span>
                                </label>

                                <label class="custom-control custom-checkbox">
                                    <input v-model="prestatus.paid" type="checkbox" class="custom-control-input">
                                    <span class="custom-control-indicator"></span>
                                    <span class="custom-control-description">{{ activeLanguage.paid }}</span>
                                </label>

                                <label class="custom-control custom-checkbox" v-if="manageOrdersPermission || !manageOrdersPermission && trxActive">
                                    <input v-model="fromQrUnified" type="checkbox" class="custom-control-input">
                                    <span class="custom-control-indicator"></span>
                                    <span class="custom-control-description">QR orders</span>
                                </label>
                            </div>
                            <div class="form-group col-md-6">
                                <label class="custom-control custom-checkbox">
                                    <input v-model="prestatus.credited" type="checkbox" class="custom-control-input">
                                    <span class="custom-control-indicator"></span>
                                    <span class="custom-control-description">{{ activeLanguage.credited }}</span>
                                </label>
                                <label class="custom-control custom-checkbox">
                                    <input v-model="prestatus.pause" type="checkbox" class="custom-control-input">
                                    <span class="custom-control-indicator"></span>
                                    <span class="custom-control-description">{{ activeLanguage.paused }}</span>
                                </label>
                                <label class="custom-control custom-checkbox">
                                    <input v-model="prestatus.incasso" type="checkbox" class="custom-control-input">
                                    <span class="custom-control-indicator"></span>
                                    <span class="custom-control-description">{{ activeLanguage.collection }}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label class="col-form-label">{{ activeLanguage.amount }}</label>
                        <div class="form-row">
                            <div class="form-group col">
                                <label class="sr-only" for="inlineFormInputGroupFrom">{{ activeLanguage.from }}</label>
                                <div class="input-group">
                                    <div class="input-group-addon">€</div>
                                    <input v-model="form.priceFrom" type="text" class="form-control" id="inlineFormInputGroupFrom" :placeholder="activeLanguage.from">
                                </div>
                            </div>
                            <div class="form-group col">
                                <label class="sr-only" for="inlineFormInputGroupTo">{{ activeLanguage.to }}</label>
                                <div class="input-group">
                                    <div class="input-group-addon">€</div>
                                    <input type="text" v-model="form.priceTo" class="form-control" id="inlineFormInputGroupTo" :placeholder="activeLanguage.to">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-md-6">
                        <label class="col-form-label">{{ activeLanguage.orderDate }}</label>
                        <div id="event_period">
                            <div class="form-row">
                                <div class="form-group col">
                                    <label class="sr-only" for="dateFrom">{{ activeLanguage.from }}</label>
                                    <div class="input-group">
                                        <Datepicker
                                            v-model="dateFrom"
                                            :calendar-button="true"
                                            calendar-button-icon="fa fa-calendar"
                                            input-class="form-control actual_range"
                                            :placeholder="activeLanguage.from"
                                            :highlighted="highlighted"
                                            :format="customDateFormatter"
                                            @selected="onDateChange($event,'dateFrom')"
                                            :language="langActive"
                                            :clear-button="true"
                                        />
                                    </div>
                                </div>
                                <div class="form-group col">
                                    <label class="sr-only" for="dateTo">{{ activeLanguage.to }}</label>
                                    <div class="input-group">
                                        <Datepicker
                                            v-model="dateTo"
                                            :calendar-button="true"
                                            calendar-button-icon="fa fa-calendar"
                                            class="right"
                                            input-class="form-control actual_range"
                                            :placeholder="activeLanguage.to"
                                            :format="customDateFormatter"
                                            @selected="onDateChange($event, 'dateTo')"
                                            :language="langActive"
                                            :clear-button="true"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-right">
                    <a href="#" class="btn hiddenDownload" style="margin-right: 5px;" @click.prevent="$emit('downloadCsv')" v-if="!userView_parent_orders">{{ activeLanguage.downloadCSV }}</a>
                </div>
                <div class="text-right">
                    <a class="btn btn-secondary" style="margin-right: 5px;" @click.prevent="clearFilters">{{ activeLanguage.clearBtn }}</a>
                    <a href="#" class="btn btn-secondary hidden-xs" style="margin-right: 5px;" @click.prevent="downloadCsv" :disabled="!formChanged" v-if="!userView_parent_orders">{{ activeLanguage.downloadCSV }}</a>
                    <button type="submit" class="btn" :disabled="!(formChanged && !clearFired)">{{ activeLanguage.applyBtn }}</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
	import Datepicker from 'vuejs-datepicker';
	import moment from 'moment';
    import {mapGetters} from  "vuex";
    import queryString from 'query-string'

	import ClientItemTemplate from '../common/reusable/ClientItemTemplate.vue'
    import { en, nl } from 'vuejs-datepicker/dist/locale'
    import  Helpers from '../../helpers/modules/scripts'

	export default {
		name: "orders-filter",
		props: {
			activeLanguage: {
				type: Object,
				required: true
			},
			showKVK: {
				type: Boolean,
                default: false
            },
            startFromDate: {},
            manageOrderRight: {
                type: Boolean,
                default: false
            },
            isAdmin: {
			    type: Boolean
            },
            disableFilterApplyOnClear: {
			    type: Boolean,
                default: false
            },
            savedFilters: {
                type: Object
            }
		},
        data() {
			return {
				show: false,
				prestatus: {},
				form: {},
                clients: [],
                client: null,
                itemTemplate: ClientItemTemplate,
				dateFrom: this.setOrderFilterDate(),
                dateTo: null,
                nl: nl,
                en: en,
                fromQrUnified: null,
        highlighted: {
          dates: [
            new Date()
          ]
        },
        formChanged: false,
        clearFired: false
            }
        },
        components: {
	        Datepicker,
	        ClientItemTemplate
        },
        mounted() {
            this.nl.months = Helpers.uppercaseArr(this.nl.months)
            this.nl.monthsAbbr = Helpers.uppercaseArr(this.nl.monthsAbbr)
            this.nl.days = Helpers.uppercaseArr(this.nl.days)
            this.form = this.savedFilters ? this.savedFilters : {}
        },
        watch: {
	        client: function(newVal, oldVal) {
	        	if (!newVal) { delete this.form.client_id }
            },
          form: function (newVal, oldVal) {
	          this.formChanged = true;

	          //allow to handle enable/disable for "Apply filter" btn
	          if (newVal === oldVal) {this.clearFired = false}
          },
          prestatus: function (newVal, oldVal) {
            this.formChanged = true;

            if (newVal === oldVal) {this.clearFired = false}
          },
          dateFrom: function (newVal, oldVal) {
            if (!this.isAdmin) {
                this.formChanged = true;

                if (newVal !== null) {
                    this.clearFired = false
                    } else {
                        this.clearFired = true
                    }
                
            }
          }
        },
		computed: {
			...mapGetters({
				manageOrdersPermission: 'userManage_orders',
				isSuperUser: 'isSuperUser',
				computedConnectedUsers: 'getComputedConnectedUsers',
                btoken: 'getBillinkToken',
                getActiveLanguageName: 'getActiveLanguageName',
                trxActive: 'getTrxPermissions',
                userView_parent_orders: 'userView_parent_orders'
            }),
            langActive () {
                return this.getActiveLanguageName === 'english' ? this.en : this.nl
            }
		},
        methods: {
	        customDateFormatter(date) {
                return moment(date).format('DD-MM-YYYY')
            },
	        downloadCsv() {
		        if ((this.formChanged && !this.clearFired)) {
                    if (!!this.form.zip) {
			        this.form.zip = this.form.zip.replace(/[^a-z0-9]/gi,'')
		            }
		            let statusArr = []
		            for (let statusItem in this.prestatus) {
			         if (this.prestatus[statusItem]) { statusArr.push(statusItem) }
		            }
		            this.form.status = statusArr

	        	    this.$emit('downloadCsv', this.form)
                }
            },
	        fillFilterOptions(data) {
	        	if (!!data.dateFrom) {
			        this.dateFrom = data.dateFrom
	        	}
	        	if (!!data.dateTo) {
			        this.dateTo = data.dateTo
                }
	        	if (!!data.client_id) {
                    this.updateClientListWithId(data.client_id)
                }

		        if (!!data.status) { data.status.forEach(item => this.prestatus[item] = true) }

                this.form = data

	        	this.show = true
            },
	        updateClientList(val, isId = false) {
	        	if (this.isSuperUser) {
			        this.$store.dispatch('startLoader')
			        this.clients = Helper.superUserFilter(val, this.computedConnectedUsers)
			        this.$store.dispatch('endLoader')
                } else {
			        billinkAxios
				        .post('app/clients/list', { username: val })
				        .then(({data}) => this.clients = data.clients)
				        .catch(err => console.error(err))
                }
            },
	        updateClientListWithId(id) {
		        billinkAxios
			        .post('app/clients/list', { id })
			        .then(({data}) => this.clientSelected(data.clients[0]))
			        .catch(err => console.error(err))
	        },
	        clientSelected(val) {
                this.client = val
	        	this.form.client_id = val.id
            },
	        getLabel (item) {
		        return !!item ? item.username : ''
	        },
			toggle() {
				this.show = !this.show
            },
            close() {
				this.show = false
            },
            setDateFromNull() {
                this.dateFrom = null
            },
	        onDateChange(date, type) {
                date ? this.form[type] = date : delete this.form[type]
            },
	        clearFilters() {
                this.form = {}
                this.prestatus = {}
                this.client = null
                this.dateTo = null
                this.dateFrom = null
                this.fromQrUnified = null
                this.clearFired = true
		        this.$emit('clearNum')
            if (!this.disableFilterApplyOnClear) {
              this.$emit('onFiltersApply', {})
            }
            },
	        applyFilters() {
	        	if (!!this.form.zip) {
			        this.form.zip = this.form.zip.replace(/[^a-z0-9]/gi,'')
		        }
				let statusArr = []
				for (let statusItem in this.prestatus) {
					if (this.prestatus[statusItem]) { statusArr.push(statusItem) }
                }
                this.form.status = statusArr
                this.form.dateFrom = this.dateFrom
                this.form.fromQrUnified = this.fromQrUnified
                this.$emit('onFiltersApply', this.form)
            },
          setOrderFilterDate() {
	          //don't change time format to avoid breaking backend
            return this.isAdmin ? moment().subtract(5, 'months').date(1).format() : null
          }
        }
	}
</script>

<style lang="scss" scoped>
    .input-group-addon:not(:last-child) {
        border-right: 0;
    }
    .input-group .form-control:not(:last-child), .input-group-addon:not(:last-child), .input-group-btn:not(:first-child)>.btn-group:not(:last-child)>.btn, .input-group-btn:not(:first-child)>.btn:not(:last-child):not(.dropdown-toggle), .input-group-btn:not(:last-child)>.btn, .input-group-btn:not(:last-child)>.btn-group>.btn, .input-group-btn:not(:last-child)>.dropdown-toggle {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .input-group-addon {
        margin-bottom: 0;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.25;
        color: #495057;
        text-align: center;
        background-color: #e9ecef;
        border: 1px solid rgba(0,0,0,.15);
        border-radius: .25rem;
    }
@media (max-width:768px) {
    .pl-3{
        padding-left: 5px !important;
    }
    .filter-content .text-right {
        display: flex;
        justify-content: space-between;
        button {
            padding: 0.65rem 1rem 0.5rem;
        }
    }
}
    @media (max-width:530px) {
       .text-right .btn {
           padding-right: 10px;
           padding-left: 10px;
       }
    }
    .hiddenDownload {
        display: none;
        margin-bottom: 15px;
    }
    @media (max-width:480px) {
        .hiddenDownload {
            display: block;
        }
    }
</style>