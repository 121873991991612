<template>
  <!--table-responsive-->
  <table class="table">
    <thead>
      <tr>
        <th v-if="check" class="table-res">
          <label class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              @change="checkAll = !checkAll"
            />
            <span class="custom-control-indicator"></span>
          </label>
        </th>
        <th class="text-center">
          {{ activeLanguage.status }}
        </th>
        <th
          @click="onSortChange('invoice')"
          :style="check ? { cursor: 'pointer' } : {}"
        >
          {{ activeLanguage.orderNum }}
          <i
            v-if="check"
            :class="setSortIconClass('invoice')"
            aria-hidden="true"
          ></i>
        </th>
        <th v-if="manageOrderRight" class="table-res pl-0">WEBSHOP</th>
        <th class="table-res">
          {{ activeLanguage.pc }}
        </th>
        <th class="text-left">
          {{ activeLanguage.clientName }}
        </th>
        <th
          class="table-res"
          @click="onSortChange('order_date')"
          :style="check ? { cursor: 'pointer' } : {}"
          style="width: 140px"
        >
          {{ activeLanguage.orderDate }}
          <i
            v-if="check"
            :class="setSortIconClass('order_date')"
            aria-hidden="true"
          ></i>
        </th>
        <th
          v-if="showKVK"
          class="table-res"
          :style="check ? { cursor: 'pointer' } : {}"
        >
          {{ activeLanguage.kvk }}
        </th>
        <th class="fixedwidth openstaand">
          {{ activeLanguage.remaining }}
        </th>
        <th
          class="fixedwidth amount table-res"
          @click="onSortChange('total')"
          :style="check ? { cursor: 'pointer' } : {}"
        >
          {{ activeLanguage.amount }}
          <i
            v-if="check"
            :class="setSortIconClass('total')"
            aria-hidden="true"
          ></i>
        </th>
      </tr>
    </thead>
    <tfoot v-if="!check" class="tfoot-no-check">
      <tr>
        <td :colspan="manageOrderRight ? 8 : 7">
          <router-link to="/orders" class="btn btn-secondary mr-sm-2">
            {{ activeLanguage.allOrdersBtn }}
          </router-link>
        </td>
      </tr>
    </tfoot>
    <tbody>
      <tr
        v-for="order in orders"
        @click.middle="onOrderClick($event, order.invoice, true)"
      >
        <td v-if="check" class="table-res no-click">
          <label
            class="custom-control custom-checkbox"
            :for="`check-${order.invoice}`"
          >
            <input
              type="checkbox"
              class="custom-control-input"
              :id="`check-${order.invoice}`"
              v-model="order.checked"
              @change="orderIsSelected(order)"
            />
            <span class="custom-control-indicator"></span>
          </label>
        </td>
        <td class="cl status-icon" @click="onOrderClick($event, order.invoice)">
          <i
            aria-hidden="true"
            class="fa"
            :class="order.class"
            v-b-tooltip.hover.right.title="order.icon"
          ></i>
        </td>
        <td class="cl" @click="onOrderClick($event, order.invoice)">
          <div class="overflow overflow-inv">{{ order.invoice }}</div>
          <div class="table-res small-date">{{ order.order_date }}</div>
        </td>
        <td
          v-if="manageOrderRight"
          class="cl table-res webshop-td pl-0"
          @click="onOrderClick($event, order.invoice)"
        >
          <div class="overflow overflow-webshop pl-0">{{ order.webshop }}</div>
          <div
            v-if="!!order.merchant_name"
            class="table-res small-date"
            style="display: block; font-size: 12px"
          >
            {{ order.merchant_name }}
          </div>
        </td>
        <td class="cl table-res" @click="onOrderClick($event, order.invoice)">
          {{
            order.type === "Consument"
              ? activeLanguage.botconsument
              : activeLanguage.consument
          }}
        </td>
        <td
          class="cl name-td"
          @click="onOrderClick($event, order.invoice)"
          v-html="'<div>' + order.customer_info + '</div'"
        />
        <td class="cl table-res" @click="onOrderClick($event, order.invoice)">
          {{ order.order_date }}
        </td>
        <td
          class="cl table-res"
          @click="onOrderClick($event, order.invoice)"
          v-if="showKVK"
        >
          <div v-if="parseFloat(order.coc_number) > 0" class="overflow">
            {{ order.coc_number }}
          </div>
        </td>
        <td
          class="cl amount table-res"
          @click="onOrderClick($event, order.invoice)"
        >
          <span>€</span> {{ formattedAmount(order.outstanding) }}
        </td>
        <td class="cl amount" @click="onOrderClick($event, order.invoice)">
          <span>€</span> {{ formattedAmount(order.total) }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "dashboard-last-order-table",
  props: {
    activeLanguage: {
      type: Object,
      required: true,
    },
    check: {
      type: Boolean,
      default: false,
    },
    sorting: {},
    orders: {
      type: Array,
      required: true,
    },
    showKVK: {
      type: Boolean,
      default: false,
    },
    manageOrderRight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checkAll: false,
    };
  },
  computed: {
    formattedAmount() {
      return (amount) => Helper.formatAmount(amount);
    },
  },
  watch: {
    checkAll: function (val) {
      let ids = [];

      this.orders.forEach((order) => {
        order.checked = val;
        if (val) {
          ids.push(order.id);
        }
      });

      this.$emit("orderIsSelected", ids);
      this.$forceUpdate();
    },
    orders: function (orders) {
      orders.forEach((order) => {
        switch (order.icon) {
          case "Betaald":
            order.class = "fa-check-circle";
            break;
          case "Nog niet gestart":
            order.class = "fa-circle";
            break;
          case "Incasso":
            order.class = "fa-exclamation-circle";
            break;
          case "Gepauzeerd":
            order.class = "fa-pause-circle";
            break;
          case "Gestart":
            order.class = "fa-play-circle";
            break;
          case "Credited":
            order.class = "fa-check-circle";
            order.icon = "Gecrediteerd";
            break;
          case "Gecrediteerd":
            order.class = "fa-circle";
            break;
        }
      });
    },
  },
  methods: {
    orderIsSelected({ checked, id }) {
      let ids = [];

      this.orders.forEach((order) => {
        if (order.checked) {
          ids.push(order.id);
        }
      });

      this.$emit("orderIsSelected", ids);
    },
    onOrderClick(e, id, isWheel = false) {
      if (isWheel || e.altKey || e.ctrlKey) {
        window.open(`/order/${id}`, "_blank");
      } else {
        this.$router.push(`/order/${id}`);
      }
    },
    onSortChange(type) {
      if (this.check) {
        this.$emit("onSortChange", type);
      }
    },
    setSortIconClass(type) {
      let className = "fa fa-sort";
      if (this.sorting.sort === type) {
        className =
          this.sorting.sort_dir === "desc"
            ? "fa fa-sort-desc"
            : "fa fa-sort-asc";
      }
      return className;
    },
  },
};
</script>

<style scoped lang="scss">
.fa-stack-2x {
  border-radius: 50%;
  background: #c1a1c7;
}
.overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  &-inv {
    width: 160px;
  }
  &-webshop {
    width: 120px;
  }
}
.name-td {
  div {
    width: 160px;
  }
}
@media (max-width: 1400px) {
  .webshop-th,
  .webshop-td {
    display: none !important;
  }
}
@media (max-width: 768px) {
  .table {
    td,
    th {
      font-size: 10px;
      padding: 2px 6px;
      vertical-align: middle;
    }
    th {
      padding: 6px;
      &.openstaand,
      &.amount {
        width: 70px;
      }
    }
    .overflow {
      width: 70px;
    }
    .tfoot-no-check td {
      text-align: center;
      & > * {
        padding: 5px;
        font-size: 11px;
        margin: 0 20px;
      }
    }
  }
}
@media (max-width: 1240px) {
  th i {
    display: none;
  }
  .cl .overflow {
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media (max-width: 480px) {
  .table {
    .cl .overflow {
      width: 80px;
    }
    td:last-child,
    .openstaand {
      border-right: 1px solid #003d5b !important;
    }
  }
}
</style>
